import React from 'react';
import {
    Wrapper,
    HeaderWrapper,
    H1,
    BodyWrapper, 
    Body,
    CvLink,
    Anchor
} from './AboutElements';
import resume from '../../documents/Dominic CV.pdf';

const AboutComponent = () => {


    const DownloadCV = () =>{
        var link = document.createElement("a");
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', resume);
        link.href = resume;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    return (
        <Wrapper>
            <HeaderWrapper>
                <H1>Hi, I am Dominic Marule</H1>
            </HeaderWrapper>
            <BodyWrapper>
                <Body>
                    I am a front-end developer and UX Designer, currently at <Anchor target="_blank" 
                    href="https://tshomokodigital.co.za">Tshomoko Digital</Anchor>. I am proudly from a 
                    small village called Leroro in the beautiful province of Mpumalanga, South Africa. 
                </Body>
                <Body className="bottom">
                    My passion is driven by working with companies with a big vision and startups with big 
                    dreams in crafting delightful experiences and creating intuitive design solutions. 
                </Body>
                <Body className="bottom">
                    At times deep house music helps me flow through my work, you can enjoy some of my favourite 
                    tunes in the playlist<Anchor href="https://open.spotify.com/playlist/18BI7Cpyv3g3InJv1OmyWS?si=WR_zlBCAQc-tbu8mamwYyg" 
                    target="_blank"> here</Anchor>. I am also a football fanatic and a staunch Chelsea FC 
                    supporter, so no Chelsea FC slander will be permitted.
                </Body>
                <Body className="download">
                    I am also available for freelance work, download my CV <CvLink onClick={DownloadCV}>here</CvLink>
                </Body>
            </BodyWrapper>
            
        </Wrapper>
    )
}

export default AboutComponent
