import React from 'react'
import ContactComponent from '../components/Contact'

const Contact = () => {
    return (
        <ContactComponent />
    )
}

export default Contact
