import React from 'react';
import AboutComponent from '../components/About';

const About = () => {
    return (
        <AboutComponent />
    )
}

export default About
