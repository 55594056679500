 import td from '../../images/iPhone X_MockUp.png';
 import dominic from '../../images/dominic.png';

/*export default [
    {
        name: "Tshomoko Digital:  Website UX design and development",
        image: require('../../images/iPhone X_MockUp.png'),
        location: '/work/tshomokodigital'
    },
    {
        name: "Dominic Marule:  Website UX design and development",
        image: require('../../images/dominic.png'),
        location: '/dominicmarule'
    }
    
];*/

const work = [
    {
      img: td,
      title: "Tshomoko Digital Website",
      role: "UX/UI and development",
      tech: "HTML, Javascript, CSS & Adobe XD",
      link: 'https://tshomokodigital.co.za'
    },
    {
      img: dominic,
      title: "Dominic Marule personal website",
      role: "UX/UI and development",
      tech: "ReactJS, CSS, Adobe XD & Photoshop",
      link: 'https://dominicmarule.co.za'
    }/*,
    {
      img: dominic,
      text: "text 3",
    }*/
  ]

  export default work