import React, {useState} from 'react';
import {FaDribbble, FaLinkedinIn, FaInstagram} from 'react-icons/fa';

import {
    Wrapper,
    MenuWrap,
    MenuWrapper,
    MenuItems,
    MenuList,
    MenuLinks,
    Burger,
    IconsWrapper,
    IconLinks
}from './HamburgerElements';

const Hamburger = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (

        <>
            <Wrapper>
                <MenuWrap onClick={toggle} >
                    <Burger onClick={toggle} isOpen={isOpen}></Burger>
                </MenuWrap>
            </Wrapper>
            <MenuWrapper isOpen={isOpen} onClick={toggle} >
                <MenuItems>
                    <MenuList>
                        <MenuLinks to='/' onClick={toggle}>Home</MenuLinks>
                    </MenuList>
                    <MenuList>
                        <MenuLinks to='/about' onClick={toggle}>About</MenuLinks>
                    </MenuList>
                    <MenuList>
                        <MenuLinks to='/work' onClick={toggle}>Work</MenuLinks>
                    </MenuList>
                    <MenuList>
                        <MenuLinks to='/contact' onClick={toggle}>Contact</MenuLinks>
                    </MenuList>
                </MenuItems>
                <IconsWrapper>
                    <IconLinks href="https://instagram.com" target='_blank'><FaDribbble /></IconLinks>
                    <IconLinks href="https://instagram.com" target='_blank'><FaInstagram /></IconLinks>
                    <IconLinks href="https://instagram.com" target='_blank'><FaLinkedinIn /></IconLinks>
                </IconsWrapper>
            </MenuWrapper>
        </>
        /*<Wrapper >
            <NavWrapper>
                <MenuIcon>
                    <FaBars />
                </MenuIcon>
            </NavWrapper>
            <MenuWrapper isOpen={isOpen} onClick={toggle}>
                <MenuItems>
                    <MenuList>
                        <MenuLinks to='/' onClick={toggle}>Home</MenuLinks>
                    </MenuList>
                    <MenuList>
                        <MenuLinks to='/about' onClick={toggle}>About</MenuLinks>
                    </MenuList>
                    <MenuList>
                        <MenuLinks to='/work' onClick={toggle}>Work</MenuLinks>
                    </MenuList>
                    <MenuList>
                        <MenuLinks to='/contact' onClick={toggle}>Contact</MenuLinks>
                    </MenuList>
                </MenuItems>
            </MenuWrapper>
        </Wrapper>*/
    )
}

export default Hamburger
