import React, {useState} from 'react';
import {
    Wrapper,
    ContentWrapper,
    ImgWrapper,
    Img,
    ImgDescription,
    ProjectTitle,
    ProjectRole,
    ProjectTech,
    ViewMore,
    NextBtnWrapper,
    NextProject,
    InnerWrapper,
    ArrowRight
} from './WorkComponents';
import work from './data'


const Projects = () => {
    
    const [index, setIndex] = useState(0);
    const {img, title, tech, role, link } = work[index];

    //set button hover state
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }

    //Check number
    const checkNumber = (number) => {
        if(number > work.length -1){
            return 0;
        }
        if(number < 0){
            return work.length - 1;
        }
        return number;
    }

    //Go to next project
    const nextProject = () => {
        setIndex((index) => {
            let newIndex = index + 1;
            return checkNumber(newIndex);
        })
    }

    //Go to previous project
   /* const prevProject = () => {
        setIndex((index) => {
            let newIndex = index-1;
            return checkNumber(newIndex);
        })
    }*/

  /* const workDisplay = images.map((item, index) => {

        return(
            <ContentWrapper key={index}>
                <ImgWrapper key={index}>
                <Img src={item.img} />
                </ImgWrapper>
                <ImgDescription key={index}>
                    <ProjectDescription key={index}>{item.text}</ProjectDescription>
                    <ViewMore to={item.text} key={index}>View More</ViewMore>
                </ImgDescription>
            </ContentWrapper>
        )
    });*/
    
    return (
        <Wrapper>
            <InnerWrapper>
                <ContentWrapper >
                    <ImgWrapper >
                    <Img src={img} />
                    </ImgWrapper>
                    <ImgDescription >
                        <ProjectTitle>{title}</ProjectTitle>
                        <ProjectRole>Role: {role}</ProjectRole>
                        <ProjectTech>Technologies: {tech}</ProjectTech>
                        <ViewMore href={link} target="_blank">Go to project</ViewMore>
                    </ImgDescription>
                </ContentWrapper>
            
            <NextBtnWrapper>
                <NextProject onClick={nextProject} onMouseEnter={onHover} onMouseLeave={onHover}>
                    Next project {hover ? <ArrowRight /> : ''}
                </NextProject>
            </NextBtnWrapper>
            </InnerWrapper>
        </Wrapper>
    )
}

export default Projects
