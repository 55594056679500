import styled from 'styled-components';
import {FaFileDownload} from 'react-icons/fa';

export const HeroContainer = styled.div`
    background-color: #000;
    height: 100vh;
`

export const ContentContainer = styled.div`
    margin-left: 20vw;
    display: flex;
    height: 100%;

    @media screen and (max-width: 768px){
        margin-left: 0;
        flex-direction: column;
    }

    @media screen and (min-width: 834px) and (max-width: 1366px) and  (min-height: 1000px){
        flex-direction: column;
        margin-left: 0;
    }

    @media screen and (max-width: 812px) and (max-height: 414px) and (orientation: landscape){
        display: flex;
        height: 100%;
        flex-direction: row;
    }
`

export const HeroText = styled.div`
    flex-basis: 50%;
    padding: 8em 0 0 0em;

    @media screen and (max-width: 768px){
        padding: 4em 0 0 1em;
        z-index: 10;
        background: transparent;
    }
    
    @media screen and (min-width: 834px) and (max-width: 1366px) and  (min-height: 1000px){
        padding: 8em 0 0 1em;
    }

    @media screen and (max-width: 812px) and (max-height: 414px) and (orientation: landscape){
        flex-basis: 60%;
    }
`

export const H1 = styled.h1`
    color: #fff;
    font-size: 30px;
    font-weight: 500;

    @media screen and (max-width: 768px){
        font-size: 20px;
    }
`

export const H2 = styled.h2`
    padding: 1.5em 0 1em 0;
    color: #fff;
    font-size: 45px;
    font-weight: 600;

    @media screen and (max-width: 1200px){
        font-size: 30px;
    }

    @media screen and (max-width: 768px){
        padding: 2em 2em 1em 0;
        font-size: 35px;
    }

    @media screen and (max-width: 441px){
        padding: 2em 2em 1em 0;
        font-size: 25px;
    }
`
export const Button = styled.a`
    border-radius: 5px;
    background: #f5b301;
    border: none;
    padding: 13px 15px;
    color: #000;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-size: 25px;
    font-weight: 400;
    text-decoration: none;
    z-index: 101;

    &:hover{
        border: 2px solid #f5b301;
        background: transparent;
        color: #fff;
    }

    @media screen and (max-width: 1200px){
        font-size: 20px;
        
    }

    @media screen and (max-width: 768px){
        font-size: 16px;
        padding: 10px 13px;
        
    }


`

export const DownloadIcon = styled(FaFileDownload)`
    margin-left: 7px;
    font-size: 20px;
    color: #f5b301;

    @media screen and (max-width: 768px){
        font-size: 16px;
    }
`

export const HeroImage = styled.div`
    flex-basis: 50%;
    width: 100%;
    height: 100%;
    background:  red;

    @media screen and (max-width: 768px){
        position: absolute;
        bottom: 0;
        height: 55vh;
    }

    @media screen and (min-width: 834px) and (max-width: 1366px) and  (min-height: 1000px){
        position: absolute;
        bottom: 0;
        height: 65vh;
    }
    @media screen and (max-width: 812px) and (max-height: 414px) and (orientation: landscape){
        flex-basis: 40%;
        margin-left: 20vw;
    }
    
`

export const ImageSrc = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
`

