import React, { useState, useLayoutEffect } from 'react'
import {
    ItemContainer,
    ItemLink,
    MenuItem,
    MenuText,
    Wrapper,
    MenuWrapper,
    MenuIcons, 
    IconLinks
} from './SideElement'
import {FaDribbble, FaLinkedinIn, FaInstagram} from 'react-icons/fa';

const Sidebar = props => {

    const {menuItems = []} = props;

    // State
    const [selected, setSelectedMenuItem] = useState(menuItems[0].name);

    // Effects

    // Set selected menu item based on URL pathname
    useLayoutEffect(() => {
        const path = window.location.pathname;
        const parts = path.split('/');

        if (path !== '/' && parts[1].charAt(0).toUpperCase() !== menuItems[0].name) {
        const selectedItem = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
        setSelectedMenuItem(selectedItem)
        }
    }, [menuItems])

    const handleMenuItemClick = (name, index) => {
        setSelectedMenuItem(name);
      }

    const menuItemsJSX = menuItems.map((item, index) =>{
        const isItemSelected = selected === item.name;

        return(
            <ItemContainer key={index}>
                <ItemLink to={item.to}>
                    <MenuItem 
                    selected={isItemSelected}
                    onClick={() => handleMenuItemClick(item.name, index)}>
                        <MenuText>{item.name}</MenuText>
                    </MenuItem>
                </ItemLink>
            </ItemContainer>
        )
    })
    return (
        <Wrapper>
            <MenuWrapper>{menuItemsJSX}</MenuWrapper>
            <MenuIcons>
                <IconLinks href="https://dribbble.com/da_rhu" target='_blank'><FaDribbble /></IconLinks>
                <IconLinks href="https://instagram.com/da_rhu" target='_blank'><FaInstagram /></IconLinks>
                <IconLinks href="https://linkedin.com/in/dominic-marule-06895680" target='_blank'><FaLinkedinIn /></IconLinks>
            </MenuIcons>
        </Wrapper>
    )
}

export default Sidebar
