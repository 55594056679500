import React from 'react';
import Projects from '../components/Work';

const Work = () => {
    return (
      <Projects />
    )
}

export default Work
