import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom';


export const Wrapper = styled.div`
    display: none;

    @media screen and (max-width: 768px){
        display: flex;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 0;
        height: 60px;
        cursor: pointer;
        background: #000;
        z-index: 2000;
    }

    @media screen and (min-width: 834px) and (max-width: 1366px) and  (min-height: 1000px){
        display: none;
        display: flex;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 0;
        height: 60px;
        cursor: pointer;
        background: #000;
        z-index: 2000;
    }
`

export const MenuWrap = styled.div`
    height: 35px;
    width:35px;
    text-align: center;
    position: absolute;
    right: 2em;
    cursor: pointer;
    z-index: 999;
`

export const Burger = styled.div`
    position: relative;
    height: 5px;
    background-color: #fff;

    transform: rotate(0);
    transition: all ease 400ms;
    opacity: 0.5;
    margin-top: 0;

    background-color: ${({isOpen}) => (isOpen) ? 'rgba(255, 255, 255, 0.0)' : ''};
    opacity: ${({isOpen}) => (isOpen) ? '1' : ''};

    &::before{
        content: '';
        position: absolute;
        display: block;
        top:15px;
        height: 5px;
        width: 100%;
        background-color: #fff;

        transition: all ease 400ms;

        transform: ${({isOpen}) => (isOpen) ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &::after{
        content: '';
        position: absolute;
        display: block;
        top:30px;
        height: 5px;
        width: 100%;
        background-color: #fff;

        transition: all ease 400ms;
        transform: ${({isOpen}) => (isOpen) ? 'rotate(45deg)' : 'rotate(0)'};
    }
`

export const MenuWrapper = styled.div`
    position: absolute;
    z-index: 2000;
    width: 100%;
    height: 100%;
    background: #000;
    align-items: center;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
    left: ${({isOpen}) => (isOpen) ? '0' : '-100%'};
    top: 60px;

    @media screen and (orientation: landscape){
        height: auto;
    }
    
`

export const MenuItems = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    list-style: none;
    padding-top: 5em;
`

export const MenuList = styled.li`
    list-style: none;
    padding: 15px 0;
`

export const MenuLinks = styled(LinkR)`
    color: #fff;
    display: flex;
    text-align: center;
    align-items: center;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    width: 100%;
    font-size: 25px;
    font-weight: 400;

    &:hover{
        color: #f5b301;
        transition: all 0.2s ease-in-out;
    }
`

export const IconsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 4em;

`

export const IconLinks = styled.a`
    color: #fff;
    text-decoration: none;
    font-size: 25px;
    margin-right: 10px;

    &:hover{
        color: #f5b301;
    }
`