import styled from 'styled-components';
import {Link as LinkR } from 'react-router-dom';
import { MdArrowForward} from 'react-icons/md'

export const Wrapper = styled.div`
    margin-left: 20vw;
    background: #000000;
    height: 100vh;
    color: #fff;

    @media screen and (max-width: 768px){
        margin-left: 0;
    }

    @media screen and (orientation: landscape) {
        height: auto;
    }
`

export const InnerWrapper = styled.div`
    
    height: 100%;
    padding-top: 4em;
    color: #fff;
    display: flex;
    
    @media screen and (max-width: 768px){
        flex-direction: column;
        margin-right: 0;
        height: 100%;
        padding: 5em 1em 0 1em;
    }
`

export const ContentWrapper = styled.div`
    flex-basis: 75%;
    position: relative;
`

export const ImgWrapper = styled.div`

    height: 60vh;
    width: 100%;

    @media screen and (max-width: 768px){
        height: 50vh;
    }

    @media screen and (max-width: 441px){
        height: 35vh;
    }

    @media screen and (max-width: 768px) and (orientation: landscape) {
        height: auto;
    }
`

export const Img = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;

    
`

export const ImgDescription = styled.div``

export const ProjectTitle = styled.p`
    padding: 1em 0 0.5em 0;
    font-size: 20px;
    font-weight: 600;

    @media screen and (max-width: 768px){
        font-size: 16px;
    }
`

export const ProjectRole = styled.p`
    padding: 0em 0 0.5em 0;
    font-size: 20px;
    font-weight: 400;

    @media screen and (max-width: 768px){
        font-size: 16px;
    }
`

export const ProjectTech = styled.p`
    padding: 0 0 1em 0;
    font-size: 20px;
    font-weight: 400;

    @media screen and (max-width: 768px){
        font-size: 16px;
    }
`

export const ViewMore = styled.a`
    color: #fff;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

    &:hover{
        color: #f5b301;
        transition: all 0.2s ease-in-out;
    }

    @media screen and (max-width: 768px){
        font-size: 16px;
    }
`

export const NextBtnWrapper= styled.div`
    flex-basis: 25%;
    position: relative;
`

export const NextProject= styled(LinkR)`
    color: #f5b301;
    background: none;
    border: none;
    outline: none;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    position: absolute;
    bottom: 3em;
    left: 2em;

    &:hover{
        transition: all 0.2s ease-in-out;
    }

    @media screen and (max-width: 768px){
        bottom: 9vh;
        left: unset;
        right: 2em;
        color: #f5b301;
        font-size: 16px;
    }

    @media screen and (max-width: 441px){
        bottom: 5em;
        right:3em;
        color: #f5b301;
        font-size: 16px;
    }
`

export const PreviousProject = styled(LinkR)`
    color: #f5b301;
    background: none;
    border: none;
    outline: none;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    &:hover{
        transition: all 0.2s ease-in-out;
    }

    @media screen and (max-width: 768px){
        bottom: 9vw;
        right:1em;
        color: #f5b301;
        font-size: 16px;
    }
`

export const ArrowRight = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;

    @media screen and (max-width: 768px){
        font-size: 16px;
    }
`