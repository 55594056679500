import styled from 'styled-components';

export const Wrapper = styled.div`
    background: #000;
    margin-left: 20vw;
    height: 100vh;
    color: #fff;
    padding-top: 6em;

    @media screen and (max-width: 768px){
        margin-left: 0;
        padding: 4em 1em 0 1em;
    }

    
    @media screen and (min-width: 834px) and (max-width: 1366px) and  (min-height: 1000px){
        margin-left: 0;
        padding: 6em 1em 0 1em;
    }
`

export const TopWrapper = styled.div`
    display: flex;
    flex-direction: column;
    
    @media screen and (max-width: 768px){}
`

export const HeaderWrapper = styled.div`
    padding-bottom: 2em;

    @media screen and (max-width: 768px){}
`

export const H1 = styled.h1`
    font-size: 30px;
    font-weight: 500;

    @media screen and (max-width: 768px){
        font-size: 20px;
    }
`

export const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1em;

    @media screen and (max-width: 768px){}

`

export const ContactWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px){
    }
    
`
export const ContactImg = styled.div`
    padding-bottom: 5px;
    font-size: 25px;

    @media screen and (max-width: 768px){
        padding-bottom: 3px;
        font-size: 20px;
    }
`

export const ImgDesc = styled.p`
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 400;
    color: #242424;

    @media screen and (max-width: 768px){
        padding-bottom: 10px;
        font-size: 16px;
    }
`

export const ContactText = styled.div`
    font-size: 20px;
    font-weight: 400;

    @media screen and (max-width: 768px){
        font-size: 16px;
    }
`

export const BottomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 4em;

    @media screen and (max-width: 768px){
        padding-top: 2em;
    }
`

export const ContactLinks = styled.a`
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;

    @media screen and (max-width: 768px){
        font-size: 16px;
    }
`
