import styled from 'styled-components'
import {NavLink as LinkR } from 'react-router-dom';

export const Wrapper = styled.div`
    color: #fff;
    width: 20vw;
    position: absolute;
    height: 100%;
    background: #000;
    
    @media screen and (max-width: 768px){
        display: none;
    }

    @media screen and (min-width: 834px) and (max-width: 1366px) and  (min-height: 1000px){
        display: none;
    }
`

export const MenuWrapper = styled.div`
    padding: 5em 0 0 0;

`

export const ItemContainer = styled.div`
`

export const ItemLink = styled(LinkR)`
    color: #fff;
    text-decoration: none;
`

export const MenuItem = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    list-style: none;
    width: 100%;
    font-size: 20px;
    font-weight: 500;

    &:hover{
        color: #f5b301;
        transition: all 0.2s ease-in-out;
    }

    &::before{
        content: '';
        position: absolute;
        display: block;
        width: 25px;
        height: 30px;
        left: -7px;

        background-color:${({selected}) => (selected) ? '#f5b301' : ''};
    }
`

export const MenuText = styled.li`
    list-style: none;
    padding: 15px 0;
`

export const MenuIcons = styled.div`
    position: absolute;
    width: 100%;
    bottom: 2em;
    left: 0;
    display: flex;
    justify-content: center;
    align-items:center;

`

export const IconLinks = styled.a`
    color: #fff;
    text-decoration: none;
    font-size: 25px;
    margin-right: 10px;

    &:hover{
        color: #f5b301;
    }
`