import React from 'react'
import HeroSection from '../components/Hero/Index'

const Home = () => {
    return (
        <>
            <HeroSection />
        </>
    )
}

export default Home
