import React from 'react';
import './App.css';
import Home from './pages';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Hamburger from './components/Hamburger';
import About from './pages/about';
import Work from './pages/work';
import Contact from './pages/contact';
import Sidebar from './components/SideBar';

function App() {
  const menuItems = [
    {name: 'Home', to: '/'},
    {name: 'About', to: '/about'},
    {name: 'Work', to: '/work'},
    {name: 'Contact', to: '/contact'}
  ];

  return (
    <Router >
      <Sidebar menuItems={menuItems}/>
      <Hamburger/>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/about' exact component={About} />
        <Route path='/work' exact component={Work}/>
        <Route path='/contact' exact component={Contact} /> 
      </Switch>
    </Router>
  );
}

export default App;
