import React from 'react';
import {
    Wrapper,
    TopWrapper,
    HeaderWrapper,
    H1,
    ContentWrapper,
    ContactWrapper,
    ContactImg,
    ImgDesc,
    ContactText,
    BottomWrapper,
    ContactLinks
} from './ContactElements';

import {FaMobileAlt, FaRegEnvelope, FaDribbble, FaLinkedinIn, FaInstagram} from 'react-icons/fa'

const ContactComponent = () => {
    return (
        <Wrapper>
            <TopWrapper>
                <HeaderWrapper>
                    <H1>Get in touch</H1>
                </HeaderWrapper>
                <ContentWrapper>
                    <ContactWrapper>
                        <ContactImg>
                            <FaMobileAlt/>
                        </ContactImg>
                        <ImgDesc>Call</ImgDesc>
                        <ContactText>+27 67021 7146</ContactText>
                    </ContactWrapper>
                    <ContactWrapper>
                        <ContactImg>
                            <FaRegEnvelope/>
                        </ContactImg>
                        <ImgDesc>Email</ImgDesc>
                        <ContactText>dominic@tshomokodigital.co.za</ContactText>
                    </ContactWrapper>
                </ContentWrapper>
            </TopWrapper>
            <BottomWrapper>
                <HeaderWrapper>
                    <H1>Connect with me</H1>
                </HeaderWrapper>
                <ContentWrapper>
                    <ContactWrapper>
                        <ContactImg>
                            <FaDribbble />
                        </ContactImg>
                        <ImgDesc>Dribbble</ImgDesc>
                        <ContactLinks href="https://dribbble.com/da_rhu" target="_blank">dribbble.com/da_rhu</ContactLinks>
                    </ContactWrapper>
                    <ContactWrapper>
                        <ContactImg>
                            <FaLinkedinIn/>
                        </ContactImg>
                        <ImgDesc>LinkedIn</ImgDesc>
                        <ContactLinks href="https://linkedin.com/in/dominic-marule-06895680" target="_blank">linkedin.com/in/dominic-marule-06895680</ContactLinks>
                    </ContactWrapper>
                    <ContactWrapper>
                        <ContactImg>
                            <FaInstagram />
                        </ContactImg>
                        <ImgDesc>Instagram</ImgDesc>
                        <ContactLinks href="https://instagram.com/da_rhu" target="_blank">instagram.com/da_rhu</ContactLinks>
                    </ContactWrapper>
                </ContentWrapper>
            </BottomWrapper>
        </Wrapper>

    )
}

export default ContactComponent
