import React, {useState} from 'react';
import {
    HeroContainer,
    ContentContainer,
    HeroText,
    H1,
    H2,
    Button,
    HeroImage,
    ImageSrc,
    DownloadIcon
} from './HeroElements'
import cover from '../../images/Image.png';
import resume from '../../documents/Dominic CV.pdf';


const HeroSection = () => {

    //set button hover state
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }

    const DownloadCV = () =>{
        var link = document.createElement("a");
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', resume);
        link.href = resume;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    return (
        <HeroContainer>
            <ContentContainer>
                <HeroText>
                    <H1>Dominic Marule</H1>
                    <H2>Front-end Developer &amp; UX Designer</H2>
                    <Button onMouseEnter={onHover} onMouseLeave={onHover} onClick={DownloadCV}>
                        Download CV {hover ? <DownloadIcon /> : ''}
                    </Button>
                </HeroText>
                <HeroImage>
                    <ImageSrc src={cover} alt=''/>
                </HeroImage>
            </ContentContainer>
        </HeroContainer>
    )
}

export default HeroSection
