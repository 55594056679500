import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #000;
    margin-left: 20vw;
    height: 100vh;

    @media screen and (max-width: 768px){
        margin-left: 0;
        padding-left: 1em;
    }

    @media screen and (min-width: 834px) and (max-width: 1366px) and  (min-height: 1000px){
        margin-left: 0;
        padding-left: 1em;
    }
`
export const HeaderWrapper = styled.div`
    padding: 8em  0 0 0;

    @media screen and (max-width: 768px){
        padding: 6em  0 0 0;
    }
`
export const H1 = styled.h1`
    font-size: 30px;
    font-weight: 500;
    color: #fff;

    @media screen and (max-width: 768px){
        font-size: 20px;
    }
`
export const BodyWrapper = styled.div`
    padding: 4em 0 0 0 ;

    @media screen and (max-width: 768px){
        padding: 2em 0 0 0 ;
    }
`
export const Body = styled.div`
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    margin-right: 4em;

    &.bottom{
        margin-top: 2em;
    }

    &.download{
        margin-top: 3em;
    }

    @media screen and (max-width: 768px){
        font-size: 16px;
        margin-right: 1em;

        &.bottom{
            margin-top: 1em;
        }
    }
    
`

export const CvLink = styled.a`
    color:  #f5b301;
    text-decoration: none;
    cursor: pointer;
`
export const Anchor = styled.a`
    color: #f5b301;
    cursor: pointer;
    text-decoration: none;
`